<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <b-link class="brand-logo">
          <Logo />
          <h2 class="brand-text text-primary ml-1">
            HOA LU UNIVERSITY
          </h2>
        </b-link>

        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Đặt lại mật khẩu"
            />
          </div>
        </b-col>

        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Đặt lại mật khẩu 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              Nhập mật khẩu mới
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="resetPasswordFormRef"
              #default="{invalid}"
            >
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="onResetPassword"
              >
                <b-form-group label-for="password">
                  <template v-slot:label>
                    Mật khẩu <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Mật khẩu"
                    vid="password"
                    rules="required|min:8|password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="getElementState(errors)"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label-for="rePassword">
                  <template v-slot:label>
                    Mật khẩu xác nhận <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Mật khẩu xác nhận"
                    rules="required|confirmed:password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="rePassword"
                        v-model="rePassword"
                        class="form-control-merge"
                        :type="rePasswordFieldType"
                        :state="getElementState(errors)"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="rePasswordToggleIcon"
                          @click="toggleRePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Xác nhận
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link :to="{ name: 'login' }">
                <feather-icon icon="ChevronLeftIcon" /> Quay lại trang đăng nhập
              </b-link>
            </p>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup,
  BFormInput, BButton, BOverlay, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'ResetPassword',
  components: {
    BOverlay,
    Logo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      password: '',
      rePassword: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      email: '',
      code: null,
      passwordFieldType: 'password',
      rePasswordFieldType: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    rePasswordToggleIcon() {
      return this.rePasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    const { email, code } = this.$route.query
    this.email = email
    this.code = code
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
      login: 'auth/login',
      verify: 'auth/verify',
    }),
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    toggleRePasswordVisibility() {
      this.rePasswordFieldType = this.rePasswordFieldType === 'password' ? 'text' : 'password'
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onResetPassword() {
      const valid = await this.$refs
        .resetPasswordFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.resetPassword({
            email: this.email,
            code: this.code,
            password: this.password,
            type: 1,
          })
          if (response) {
            const { isSuccessful, message, username } = response
            if (isSuccessful && !!username) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Đặt lại mật khẩu thành công',
                  icon: 'XCircleIcon',
                  variant: 'success',
                },
              })
              await this.autoLogin(username)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async autoLogin(username) {
      await this.login({ username, password: this.password })
      const user = await this.verify()
      if (user) {
        this.$ability.update(user.abilities)
        try {
          await this.$router.replace({ name: 'dashboard' })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        useJwt.handleLogout()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không lấy được thông tin người dùng',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
